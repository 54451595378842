import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import CustomBreadCrumb from 'components/Breadcrumb'
import CustomLink from 'components/CustomLink'
import Workings from 'components/Wachtlijst/Workings'

// Third Party
import styled from 'styled-components'
import parse from 'html-react-parser'

const StyledCustomLink = styled(CustomLink)`
  p {
    background-color: ${props => props.theme.color.contrast};
    color: ${props => props.theme.color.text.light};
    border-radius: 5px;
    padding-bottom: 0 !important;
    width: 200px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.25s;
  }

  &:hover {
    p {
      transform: scale(1.05);
    }
  }
`

const StyledImg = styled(Img)`
  height: 100%;
  pointer-events: none;
  z-index: -1;
`

const ImageContainerText = styled.div`
  margin-top: -100px;
  color: ${props => props.theme.color.text.light};

  @media screen and (max-width: 991px) {
    margin-top: -60px;
  }
`

const ImageContainer = styled.div`
  height: 330px;

  @media screen and (max-width: 767px) {
    height: 230px;
  }

  @media screen and (max-width: 483px) {
    height: 170px;
  }
`

const ContentContainer = styled.div`
  h1 {
    font-weight: ${props => props.theme.font.weight.xl};
    font-size: ${props => props.theme.font.size.xxl};
    padding-bottom: 2rem;
  }

  p {
    font-size: ${props => props.theme.font.size.sm};
    line-height: 24px;
    padding-bottom: 1rem;
  }

  h2 {
    color: ${props => props.theme.color.text.contrast};
    font-weight: ${props => props.theme.font.weight.xl};
    font-size: ${props => props.theme.font.size.l};
  }
`

const Wachtlijst = ({ pageContext }) => {
  const { page: { yoastMeta, title, path, acf }, locations: {edges: locations } } = useStaticQuery(graphql`
    {
      page: wordpressPage(wordpress_id: {eq: 275}) {
        title
        path
        acf {
          banner_image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          intro {
            description
          }
          workings {
            title
            points {
              text
            }
          }
        }
        yoastMeta: yoast_meta {
          name
          content
          property
        }
      }
      locations: allWordpressWpLocaties {
        edges {
          node {
            title
            slug
            acf {
              location_title
              secondary_location
            }
          }
        }
      }
    }
  `)

  const options = []

  locations.forEach(({ node }) => {
    const subLocations = locations.filter(({ node: { acf: { location_title: locationTitle } } }) => locationTitle === node.acf.location_title)
    const edges = { label: node.acf.location_title, value: `/wachtlijst/${node.slug}` }

    if(options.find(({ label }) => label === node.acf.location_title) === undefined) {
      if(subLocations.length > 1) {
        options.push({ ...{ options: subLocations.map(({ node: subNode }) => { return { label: subNode.acf.secondary_location, value: `/wachtlijst/${subNode.slug}` } }) }, ...edges })
      } else {
        options.push(edges)
      }
    }
  })

  return (
    <Layout>
      <SEO yoast={{ meta: yoastMeta }} pathname={path} />
      <div>
        <ImageContainer>
          <StyledImg className="d-flex" alt="" fluid={acf.banner_image.localFile.childImageSharp.fluid} />
          <ImageContainerText className="container">
            <div className="title font-size-xxl font-weight-xl">
              <h2 className="color-text-light font-weight-xl">{parse(title)}</h2>
            </div>
          </ImageContainerText>
        </ImageContainer>
        <CustomBreadCrumb data={title} className="py-2" pageContext={pageContext} />
        <div className="container mb-5 pt-lg-5 pb-3 pb-lg-5">
          <div className="row mb-5 py-4">
            <div className="col-lg-7">
              <ContentContainer>
                {parse(acf.intro.description)}
                <div className="row">
                  {options.map(location => (
                    <div className="col-sm-6">
                      <StyledCustomLink to={location.value} className="d-flex justify-content-center mb-3">
                        <p className="mb-0 font-weight-l">{parse(location.label)}</p>
                      </StyledCustomLink>
                    </div>
                  ))}
                </div>
               
              </ContentContainer>
            </div>
            <div className="col-lg-5">
              <div>
                <Workings data={acf.workings} />
              </div>
            </div>
          </div>
        </div>
        <div className="py-lg-5" />
      </div>
    </Layout>
  )
}

export default Wachtlijst